import { render, staticRenderFns } from "./DividerBlock.vue?vue&type=template&id=5be4ccec&"
import script from "./DividerBlock.vue?vue&type=script&lang=ts&"
export * from "./DividerBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports