<template>
  <b-modal
    id="carousel-popout"
    hide-header
    hide-footer
    centered
    size="xl"
    body-class="p-0 bg-gray-1"
  >
    <carousel v-model="currentSlideIndexModel" :images="images" />
  </b-modal>
</template>
<script>
import Vue from 'vue';
import Carousel from './ImageCarousel.vue';

export default Vue.extend({
  components: {
    Carousel,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentSlideIndexModel: {
      get() {
        return this.value;
      },
      set(newSlideIndex) {
        this.$emit('input', newSlideIndex);
      },
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@/assets/variables';
::v-deep {
  .carousel div {
    max-height: 80vh !important;
  }
}
</style>
