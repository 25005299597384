import { Feature, Geometry, Polygon, polygon as turfPolygon } from '@turf/helpers';
import { getGeoJsonGeometry } from '@nbtsolutions/vetro-mapbox';
import { LngLatBounds } from 'mapbox-gl';

export const geometry = {
  point: (coords: Array<number>): Geometry => getGeoJsonGeometry(coords, 'point'),
  lineString: (coords: Array<Array<number>>): Geometry => getGeoJsonGeometry(coords, 'linestring'),
};

export const bboxToPolygon = (bbox: LngLatBounds): Feature<Polygon> => {
  if (typeof bbox?.getSouthWest !== 'function') {
    throw new Error('bbox must be a mapbox bbox');
  }

  const sw = bbox.getSouthWest().toArray();
  const nw = bbox.getNorthWest().toArray();
  const ne = bbox.getNorthEast().toArray();
  const se = bbox.getSouthEast().toArray();

  const coordinates = [[sw, nw, ne, se, sw]];

  return turfPolygon(coordinates); // MultiPolygon
};
