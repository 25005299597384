
















import Vue, { PropType } from 'vue';
import { DropdownBlockConfig, Feature, InputBlockConfig } from '@/types';
import ValidatedAttributeInput from '@/components/side-bar/content/blocks/sub-components/ValidatedAttributeInput.vue';

export default Vue.extend({
  name: 'dropdown-input-block',
  props: {
    config: { type: Object as PropType<DropdownBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    validationMethods: { type: Object, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  components: { ValidatedAttributeInput },
  computed: {
    inputConfig(): InputBlockConfig {
      return {
        type: this.config.type,
        key: this.config.key,
        label: this.config.label,
        attribute: this.config.attribute,
        required: this.config.required,
        // A null value will be considered a value and validated immediately
        //  but undefined will be ignored until the user selects something
        defaultValue: this.config.defaultOption ?? undefined,
      };
    },
  },
});
