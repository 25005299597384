import _pick from 'lodash.pick';
import _difference from 'lodash.difference';
import axios from 'axios';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { polygonToLine } from '@turf/polygon-to-line';
import { point as turfPoint } from '@turf/helpers';
import { fetchFeatures, getIntersectingFeatures } from '@/util/features';
import { bboxToPolygon } from './geoJson';

const DragFreeDirectSelect = { ...MapboxDraw.modes.direct_select, dragFeature() {} };

// We are using a let here due to scoping issues with `this.sourceGeomCache`
// Previously the `this` context did not allow for proper clearing of sourceGeomCache
// Which caused ghosting of previous coincident Line locations on the map
let sourceGeomCache = {};

const getSnapGeometry = (feature) => {
  let { geometry } = feature;

  if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
    ({ geometry } = polygonToLine(geometry));
  }

  return geometry;
};

export const setupDraw = () => {
  return new MapboxDraw({
    displayControlsDefault: false,
    snapLayerFilter: (l) => {
      const correspondsToVectorLayer = l.id.match(
        /^(dashed-line-|polygon-outline-|circle-)?mapbox-layer-\d+-.+$/,
      );
      const isVisible = l.layout?.visibility === 'visible';
      // we only want to snap to polygon outline layers
      const isNotMainPolygonLayer =
        l.id.startsWith('polygon-outline-') || !l.id.endsWith('polygon');

      return correspondsToVectorLayer && isVisible && isNotMainPolygonLayer;
    },
    async fetchSourceGeometries(vetroIds) {
      this._setGeomCacheIfNotExists();

      const cached = _pick(sourceGeomCache, vetroIds);

      const uncached = _difference(vetroIds, Object.keys(cached));

      if (uncached.length === 0) return vetroIds.map((vetroId) => cached[vetroId].geometry);

      const { result: features } = await fetchFeatures(uncached);

      this._updateSourceGeomCache(features);

      return vetroIds.map((vetroId) => sourceGeomCache[vetroId].geometry);
    },
    async fetchSourceGeometry(vetroId) {
      this._setGeomCacheIfNotExists();

      const cached = sourceGeomCache[vetroId];

      if (cached) {
        return cached.geometry;
      }

      const {
        result: [feature],
      } = await fetchFeatures([vetroId]);

      this._updateSourceGeomCache([feature]);

      return sourceGeomCache[vetroId].geometry;
    },
    // mapbox-gl-draw will do snapping calculations for us
    // fetchSnapGeometry will always return a geometry; might be point/line/polygon
    async fetchSnapGeometry(mapboxFeature) {
      this._setGeomCacheIfNotExists();

      const { geometry, properties } = mapboxFeature;
      const { vetro_id: snapToVetroId } = properties;

      if (!snapToVetroId) return geometry;

      try {
        const [featureGeometry] = await this.fetchSourceGeometries([snapToVetroId]);

        return featureGeometry;
      } catch (error) {
        // cancel token error is undefined
        if (error.message !== undefined) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        return null;
      }
    },
    async fetchSnapGeometries(mapboxFeatures) {
      const vetroIds = mapboxFeatures.map((f) => f.properties.vetro_id);
      const geometries = (await this.fetchSourceGeometries(vetroIds.filter((el) => el))).reverse();
      return mapboxFeatures.map((mf) => (mf.properties.vetro_id ? geometries.pop() : mf.geometry));
    },
    resetSnappingGeomCache() {
      sourceGeomCache = {};
    },
    async getClosestPoint(vetroId, lng, lat) {
      const { data } = await axios.get(`/v2/features/${vetroId}/closest_point`, {
        params: { lng, lat },
      });

      const { result: snapGeometry } = data;

      return turfPoint(snapGeometry.coordinates);
    },
    async fetchMapExtentGeometry(planIds, layerIds, excludedAttributes = []) {
      // short circuit fetch if there are too many features to pre-fetch
      const featureCount = this.map.queryRenderedFeatures({
        filter: [
          'all',
          ['in', ['get', 'plan_id'], ['literal', planIds]],
          ['in', ['get', 'layer_id'], ['literal', layerIds]],
        ],
      }).length;

      if (featureCount > 50000) return;

      const bbox = this.map.getBounds();
      const polygon = bboxToPolygon(bbox);

      this._setGeomCacheIfNotExists();

      const features = await getIntersectingFeatures({
        geometry: polygon.geometry,
        layerIds,
        planIds,
        excludedAttributes,
      });
      this._updateSourceGeomCache(features);
    },
    _setGeomCacheIfNotExists() {
      if (!sourceGeomCache) sourceGeomCache = {};
    },
    _updateSourceGeomCache(features) {
      features.forEach((feature) => {
        const geometry = getSnapGeometry(feature);
        sourceGeomCache[feature.xVetro.vetroId] = { geometry };
      });
    },
    modes: {
      ...MapboxDraw.modes,
      static: StaticMode,
      direct_select: DragFreeDirectSelect,
    },
    keybindings: true,
    clickBuffer: 5,
    defaultMode: 'static',
  });
};
