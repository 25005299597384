
























import Vue, { PropType } from 'vue';
import { mapActions, mapState } from 'vuex';
import { SpeedTestBlockConfig } from '@/types';
import doSpeedTest, { CallbackFns } from './speed-test';
import Gauge from './Gauge.vue';

interface SpeedTestBlockData {
  downloadSpeed: string;
  uploadSpeed: string;
  workerTerminateFn: null | (() => void);
  status: string;
}

export default Vue.extend({
  name: 'speed-test-block',
  components: {
    Gauge,
  },
  props: {
    config: { type: Object as PropType<SpeedTestBlockConfig>, required: true },
  },
  data(): SpeedTestBlockData {
    return {
      downloadSpeed: '0',
      uploadSpeed: '0',
      workerTerminateFn: null,
      status: 'Testing',
    };
  },
  methods: {
    ...mapActions('sidebar', ['setSurveyData']),
    async startSpeedTest() {
      const callbacks: CallbackFns = {
        measurement: (m) => {
          if (m.test === 'download') {
            this.downloadSpeed = m.speed.toFixed(1);
          } else {
            this.uploadSpeed = m.speed.toFixed(1);
          }
        },
        error: (e) => {
          // eslint-disable-next-line no-console
          console.error(e.error);
        },
        complete: () => {
          this.terminateSpeedTestWorkers();
          this.sendValues();
          this.status = 'Complete';
        },
      };
      this.workerTerminateFn = await doSpeedTest(callbacks);
    },
    terminateSpeedTestWorkers() {
      if (this.workerTerminateFn) {
        this.workerTerminateFn();
      }
    },
    sendValues() {
      this.setSurveyData({
        key: this.config.downloadAttributeName ?? 'downloadSpeed',
        value: this.downloadSpeed,
      });
      this.setSurveyData({
        key: this.config.uploadAttributeName ?? 'uploadSpeed',
        value: this.uploadSpeed,
      });
    },
  },
  computed: {
    ...mapState('config', ['styles']),
  },
  async mounted() {
    this.startSpeedTest();
  },
  beforeDestroy() {
    this.terminateSpeedTestWorkers();
  },
});
