






















































import Vue, { PropType } from 'vue';
import { Feature } from '@/types';
import PlusIcon from '@/assets/icons/plus.svg?inline';
import FeatureLabel from '@/components/util/FeatureLabel.vue';
import ClickingHandIcon from '@/assets/icons/clicking-hand.svg?inline';
import TrashCanIcon from '@/assets/icons/trash-can.svg?inline';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'layer-selection-popup',
  components: {
    PlusIcon,
    FeatureLabel,
    TrashCanIcon,
    ClickingHandIcon,
  },
  props: {
    features: {
      type: Array as PropType<Feature[]>,
      required: true,
    },
    parentlessCommentsEnabled: { type: Boolean, required: true },
  },
  computed: {
    ...mapState('features', ['multiSelectEnabled']),
  },
  methods: {
    selectFeature(feature: Feature) {
      this.$emit('on-feature-select', feature);
    },
  },
});
