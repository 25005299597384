import { PaddingOptions } from 'mapbox-gl';
import { SCREEN_BREAKPOINT_MIN, SIDEBAR_SCREEN_WIDTH } from '@/constants';

export const getMapSidebarPadding = (isActive: boolean): PaddingOptions => {
  // Not a source of truth for these values, an interpretation of src/assets/variables.scss + Sidebar.vue
  const { XS, SM, MD, LG, XL } = SCREEN_BREAKPOINT_MIN;
  const sidebarMapPaddings = [
    // For mobile, sidebar is about half the screen height and full width.
    { screenWidth: XS, paddingLeft: 0, paddingBottom: window.innerHeight / 2 },
    { screenWidth: SM, paddingLeft: SIDEBAR_SCREEN_WIDTH.get(SM), paddingBottom: 0 },
    { screenWidth: MD, paddingLeft: SIDEBAR_SCREEN_WIDTH.get(MD), paddingBottom: 0 },
    { screenWidth: LG, paddingLeft: SIDEBAR_SCREEN_WIDTH.get(LG), paddingBottom: 0 },
    { screenWidth: XL, paddingLeft: SIDEBAR_SCREEN_WIDTH.get(XL), paddingBottom: 0 },
  ];
  const mapPadding = sidebarMapPaddings.reduce((prev, current) =>
    window.innerWidth >= current.screenWidth ? current : prev,
  );
  const paddingLeft = isActive ? mapPadding.paddingLeft : 0;
  const { paddingBottom } = mapPadding;
  return { left: paddingLeft ?? 0, right: 0, top: 0, bottom: paddingBottom };
};
