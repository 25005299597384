































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import LayerList from './LayerList.vue';

export default Vue.extend({
  name: 'layer-legend',
  components: {
    LayerList,
  },
  props: {
    legendIsActive: { type: Boolean, required: true },
    sidebarIsActive: { type: Boolean, required: true },
  },
  computed: {
    ...mapState('layers', ['layerStatusMap', 'categorizedAttributeStatusMap']),
    ...mapGetters('layers', [
      'layerById',
      'layersByCategoryId',
      'allLayersActiveForCategory',
      'sortedCategoriesWithLayers',
    ]),
    ...mapGetters('legend', ['shouldDisplayCategories']),
    layerLegendToggleIcon(): string {
      return this.legendIsActive ? '»' : '«';
    },
    shouldShowLegend(): boolean {
      return Object.values(this.layersByCategoryId).length > 0;
    },
  },
  methods: {
    ...mapActions('layers', ['toggleLayer', 'toggleCategory', 'toggleCategorizedAttribute']),
  },
});
