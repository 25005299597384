













import { mapState } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  props: {
    files: Array,
  },
  computed: {
    ...mapState('user', ['fileAttributeDomain']),
  },
  methods: {
    keyToUrl(key: string) {
      return `https://${this.fileAttributeDomain}/${key}`;
    },
  },
});
