






















import Vue, { PropType } from 'vue';
import { mapActions, mapState } from 'vuex';
import { MglGeojsonLayer } from 'v-mapbox';
import { Map, PointLike } from 'mapbox-gl';
import { FeatureCollection } from '@turf/helpers';
import { Feature } from '@/types';
import {
  CLUSTER_SOURCE_ID,
  getClusterLayer,
  CLUSTER_COUNT_LAYER,
  CLUSTER_LAYER_ID,
  UNCLUSTERED_LAYER_ID,
  getUnclusteredLayer,
  getClusterPointsAtPoint,
} from '@/util/clustering';

type GeoJSONSource = {
  type: 'geojson';
  data: FeatureCollection;
  cluster?: boolean;
  clusterMaxZoom?: number;
  clusterRadius?: number;
};

interface HoverEvent {
  point: PointLike;
}

export default Vue.extend({
  name: 'comment-clusters',
  components: {
    MglGeojsonLayer,
  },
  props: {
    map: { type: Object as PropType<Map> },
    show: { type: Boolean, required: true },
  },
  data(): {
    polygonCentroidsByVetroId: Record<string, Feature>;
    CLUSTER_SOURCE_ID: string;
  } {
    return {
      polygonCentroidsByVetroId: {},
      CLUSTER_SOURCE_ID,
    };
  },
  computed: {
    ...mapState('comments', ['hoveredClusterInfo', 'comments']),
    clusterLayer(): unknown {
      return getClusterLayer(this.hoveredClusterInfo.id);
    },
    clusterCountLayer() {
      return CLUSTER_COUNT_LAYER;
    },
    unclusteredLayer(): unknown {
      return getUnclusteredLayer(this.hoveredClusterInfo.id);
    },
    featuresGeoJson(): FeatureCollection {
      return {
        type: 'FeatureCollection',
        features: this.comments.map((comment: Feature) => ({
          ...comment,
          properties: { ...comment.properties, vetroId: comment.xVetro?.vetroId },
        })),
      };
    },
    source(): GeoJSONSource {
      return {
        type: 'geojson',
        data: this.featuresGeoJson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      };
    },
  },
  beforeDestroy() {
    this.map.off('mouseenter', CLUSTER_LAYER_ID, this.setHoveredClusterChildren);
    this.map.off('mouseleave', CLUSTER_LAYER_ID, this.handleMouseLeave);
    this.map.off('mouseenter', UNCLUSTERED_LAYER_ID, this.setHoveredClusterChildren);
    this.map.off('mouseleave', UNCLUSTERED_LAYER_ID, this.handleMouseLeave);
    this.map.off('mouseout', this.handleMouseLeave);
  },
  watch: {
    show: {
      handler(show) {
        if (show) {
          this.map.on('mouseenter', CLUSTER_LAYER_ID, this.setHoveredClusterChildren);
          this.map.on('mouseleave', CLUSTER_LAYER_ID, this.handleMouseLeave);
          this.map.on('mouseenter', UNCLUSTERED_LAYER_ID, this.setHoveredClusterChildren);
          this.map.on('mouseleave', UNCLUSTERED_LAYER_ID, this.handleMouseLeave);
          this.map.on('mouseout', this.handleMouseLeave);
        } else {
          this.map.off('mouseenter', CLUSTER_LAYER_ID, this.setHoveredClusterChildren);
          this.map.off('mouseleave', CLUSTER_LAYER_ID, this.handleMouseLeave);
          this.map.off('mouseenter', UNCLUSTERED_LAYER_ID, this.setHoveredClusterChildren);
          this.map.off('mouseleave', UNCLUSTERED_LAYER_ID, this.handleMouseLeave);
          this.map.off('mouseout', this.handleMouseLeave);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('comments', ['setHoveredClusterInfo']),
    handleMouseLeave() {
      this.setHoveredClusterInfo(null);
    },
    async setHoveredClusterChildren({ point }: HoverEvent) {
      const clusterInfo = await getClusterPointsAtPoint(this.map, point, {
        includeUnclustered: true,
      });

      this.setHoveredClusterInfo(clusterInfo);
    },
  },
});
