











import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import MapContainer from '@/components/MapContainer.vue';
import OverlayContainer from '@/components/OverlayContainer.vue';
import GoogleTagManager from '@/components/analytics/GTM.vue';
import UserflowManager from '@/components/analytics/UserflowManager.vue';

export default Vue.extend({
  name: 'map-view',
  components: {
    GoogleTagManager,
    UserflowManager,
    MapContainer,
    OverlayContainer,
  },
  computed: {
    ...mapState('config', ['viewAttributes']),
    ...mapGetters('config', ['googleTagManagerPublicId']),
  },
});
