













import { mapActions, mapState } from 'vuex';
import { AddressSearchResult } from '@/types';
import PureSearch from './PureSearch.vue';

export default {
  name: 'search',
  props: {
    searchPlaceholder: { type: String, required: false },
  },
  components: {
    PureSearch,
  },
  computed: {
    ...mapState('search', ['addressPredictions', 'selectedAddress']),
  },
  methods: {
    ...mapActions('search', [
      'fetchAddressPredictions',
      'resetAddressPredictions',
      'setSelectedAddress',
      'resetSelectedAddress',
    ]),
    ...mapActions('spinners', ['activateSpinner', 'deactivateSpinner']),
    async handleSelectAddress(item: AddressSearchResult): Promise<void> {
      await this.setSelectedAddress(item);
      this.resetAddressPredictions();
    },
  },
};
