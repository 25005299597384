




























import store from '@/store'; // Import the Vuex store instance
import Vue, { PropType } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import booleanIntersects from '@turf/boolean-intersects';
import _isEqual from 'lodash.isequal';
import _uniq from 'lodash.uniq';
import { Map, LngLat, Point, Control } from 'mapbox-gl';
import { Position, Geometry, Polygon } from '@turf/helpers';
import { Feature, NullableGeometry } from '@/types';
import { MglPopup } from 'v-mapbox';
import { getClusterPointsAtPoint, zoomToFeatures } from '@/util/clustering';
import { getIntersectingFeatures } from '@/util/features';
import { pointFromPosition } from '@/util/geom';
import { DEFAULT_JUMP_ZOOM, SELECTION_MODES } from '@/constants';
import { getLatLonPolygonFromPixel } from '@nbtsolutions/vetro-mapbox';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxDrawComponent from './Draw.vue';
import FeatureDisambiguationMenu from './FeatureDisambiguationMenu.vue';

export const DEFAULT_CURSOR = '';
export const POINTER_CURSOR = 'pointer';

interface ClickEvent {
  lngLat: LngLat;
  point: Point;
}

interface FeatureSelectionManagerData {
  draw: MapboxDraw | null;
  lastClickLngLat: LngLat | null;
  mapClickCoordinates: Position | null;
  showPopup: boolean;
  featuresAtClick: Feature[];
}

export default Vue.extend({
  name: 'feature-selection-manager',
  components: {
    MglPopup,
    MapboxDrawComponent,
    FeatureDisambiguationMenu,
  },
  props: {
    selectionMode: String,
    map: { type: Object as PropType<Map>, required: true },
    layerIdGeomTypeMap: { type: Object as PropType<Record<number, string>>, required: true },
  },
  data(): FeatureSelectionManagerData {
    return {
      draw: null,
      lastClickLngLat: null,
      mapClickCoordinates: null,
      showPopup: false,
      featuresAtClick: [],
    };
  },
  async mounted() {
    this.enterClickDrawMode();
    //  Getting view key from url params and applying them to GET request for view
  },
  destroyed() {
    if (this.map) {
      this.map.off('mouseup', this.onMouseUp);
      this.map.off('mousedown', this.onMouseDown);
    }
    document.removeEventListener('keydown', this.setMultiSelect);
    document.removeEventListener('keyup', this.setMultiSelect);
  },
  computed: {
    ...mapState('config', ['planIds', 'viewAttributes']),
    ...mapState('comments', ['showCommentsOnMap']),
    ...mapState('features', ['selectedFeatures']),
    ...mapGetters('layers', ['activeLayerIds', 'excludedCategorizedStyleAttributes']),
    ...mapGetters('config', [
      'parentlessCommentsEnabled',
      'commentableLayerIds',
      'allowCommentOnSelectedFeature',
      'getViewAttributes',
    ]),
    drawPreCacheParams(): { planIds: Array<number>; layerIds: Array<number> } {
      return { planIds: this.planIds, layerIds: this.activeLayerIds };
    },
    activeMode(): string {
      if (
        this.selectionMode === SELECTION_MODES.LASSO_SELECT ||
        this.selectionMode === SELECTION_MODES.LASSO_DESELECT
      ) {
        return 'draw_freehand_polygon';
      }
      if (this.selectionMode === SELECTION_MODES.CLICK) {
        return 'simple_select';
      }
      return 'static';
    },
    featureOptions(): Feature[] {
      return this.featuresAtClick.filter((feature) =>
        this.activeLayerIds.includes(feature.xVetro.layerId),
      );
    },
    multiSelectFlag(): boolean {
      return this.getViewAttributes.multiSelect;
    },
  },
  watch: {
    selectionMode(mode) {
      if (this.draw) {
        if (mode === SELECTION_MODES.LASSO_SELECT || mode === SELECTION_MODES.LASSO_DESELECT) {
          this.enterFreehandDrawMode();
        } else {
          this.enterClickDrawMode();
        }
      }
    },
  },
  methods: {
    ...mapActions('features', ['setSelectedFeature', 'setFeaturesAtFocusPoint']),
    ...mapActions('sidebar', ['setSurveyActive']),
    ...mapActions('map', ['setActiveLocation', 'resetActiveLocation']),
    async handlePolygonSelection({
      features: [adhocPolygon],
    }: {
      features: Array<Feature<Polygon>>;
    }) {
      if (this.draw) {
        this.draw.deleteAll();
      }
      const polygonGeometry = adhocPolygon.geometry as Polygon;
      await this.fetchFeaturesByPolygon(polygonGeometry);
      this.enterFreehandDrawMode();
    },
    async fetchFeaturesByPolygon(polygonGeom: Polygon) {
      if (polygonGeom) {
        await this.fetchFeaturesAtClick(polygonGeom, this.activeLayerIds);
        const selectedFeatureVetroIds = new Set(
          this.selectedFeatures.map((feature: Feature<NullableGeometry>) => feature.xVetro.vetroId),
        );
        const currentSelection = this.featuresAtClick.reduce(
          (
            agg: {
              featuresToSelect: Array<Feature<NullableGeometry>>;
              featuresToDeselect: Array<Feature<NullableGeometry>>;
              isNotApplied: boolean;
            },
            feature: Feature<NullableGeometry>,
          ) => {
            const matchingClickedFeature = selectedFeatureVetroIds.has(feature.xVetro.vetroId);
            if (!matchingClickedFeature && this.selectionMode === SELECTION_MODES.LASSO_SELECT) {
              agg.featuresToSelect.push(feature);
            } else if (
              matchingClickedFeature &&
              this.selectionMode === SELECTION_MODES.LASSO_DESELECT
            ) {
              agg.featuresToDeselect.push(feature);
            }
            return agg;
          },
          {
            featuresToSelect: [],
            featuresToDeselect: [],
            isNotApplied: false,
          },
        );
        this.$emit('apply-selection-change', currentSelection);
      }
    },
    checkPolygonDrawMode() {
      // This check is needed so we can continuously create
      // any number of polygons. Without this after some number
      // of polygons the drawing tool changes and the user is
      // no longer able to draw polygons.
      if (this.draw && this.draw.getMode() === 'draw_freehand_polygon') {
        this.draw.changeMode('draw_freehand_polygon');
      }
    },
    enterFreehandDrawMode() {
      if (this.draw && this.map) {
        this.map.off('mousedown', this.onMouseDown);
        this.map.off('mouseup', this.onMouseUp);
        document.removeEventListener('keydown', this.setMultiSelect);
        document.removeEventListener('keyup', this.setMultiSelect);

        this.map.on('draw.create', this.handlePolygonSelection);
        this.map.on('mousedown', this.checkPolygonDrawMode);

        this.draw.changeMode('draw_freehand_polygon');
        this.map.getCanvas().style.cursor = POINTER_CURSOR;
      }
    },
    enterClickDrawMode() {
      if (this.draw && this.map) {
        this.map.off('draw.create', this.handlePolygonSelection);
        this.map.off('mousedown', this.checkPolygonDrawMode);

        this.map.on('mousedown', this.onMouseDown);
        this.map.on('mouseup', this.onMouseUp);
        document.addEventListener('keydown', this.setMultiSelect);
        document.addEventListener('keyup', this.setMultiSelect);

        this.draw.changeMode(this.draw.modes.STATIC);
        this.map.getCanvas().style.cursor = DEFAULT_CURSOR;
      }
    },
    onDrawLoad(draw: MapboxDraw) {
      if (draw) {
        this.draw = draw;
        if (this.map) {
          this.map.addControl(this.draw as unknown as Control);
        }
      } else {
        if (this.map) {
          this.map.removeControl(this.draw as unknown as Control);
        }
        this.draw = draw;
      }
    },
    onMouseDown(event: ClickEvent) {
      this.lastClickLngLat = event.lngLat;
    },
    onMouseUp(event: ClickEvent) {
      if (_isEqual(this.lastClickLngLat, event.lngLat)) {
        this.handleMapClick(event);
      }
    },
    setMultiSelect(event: KeyboardEvent) {
      if (this.multiSelectFlag && !this.showPopup) {
        if (event.metaKey || event.ctrlKey) {
          store.commit('features/SET_MULTI_SELECT_ENABLED');
        } else if (!event.metaKey || !event.ctrlKey) {
          store.commit('features/SET_MULTI_SELECT_DISABLED');
        }
      }
    },
    async handleMapClick(event: ClickEvent) {
      if (this.map && !this.showPopup) {
        this.mapClickCoordinates = [event.lngLat.lng, event.lngLat.lat];
        if (this.viewAttributes.selectionBounds) {
          const isInBounds = booleanIntersects(
            this.viewAttributes.selectionBounds,
            pointFromPosition(this.mapClickCoordinates),
          );
          if (!isInBounds) {
            return;
          }
        }

        if (this.showCommentsOnMap && this.selectionMode === SELECTION_MODES.CLICK) {
          const { features: clusterFeatures, isCluster } = await getClusterPointsAtPoint(
            this.map,
            event.point,
            { includeUnclustered: true },
          );

          // If the user clicked on a cluster, we'll zoom to the the bounding box of the cluster
          // except for the case that the cluster is all in one polygon, in which case we'll treat it as a normal click.
          const numUniqueFeatures = new Set(clusterFeatures.map((f) => JSON.stringify(f.geometry)))
            .size;

          if (isCluster && numUniqueFeatures > 1) {
            zoomToFeatures(this.map, clusterFeatures);
            return;
          }
          if (numUniqueFeatures > 0) {
            const geometry = clusterFeatures[0].geometry as Geometry;
            const [lng, lat] = geometry.coordinates;

            this.map.flyTo({ center: { lng, lat } as LngLat, zoom: DEFAULT_JUMP_ZOOM, speed: 5 });
            return;
          }
        }
        let polygon: Polygon | null = null;
        if (this.selectionMode === SELECTION_MODES.CLICK) {
          const [x, y] = [event.point.x, event.point.y];

          polygon = getLatLonPolygonFromPixel({
            point: { x, y },
            map: window.map,
          });
        }
        if (this.parentlessCommentsEnabled && polygon) {
          await this.handleFeatureClickLaxCommentParent(polygon);
        } else if (polygon) {
          await this.handleFeatureClickStrictCommentParent(polygon);
        }
      }
    },
    async fetchFeaturesAtClick(polygon: Polygon, layerIds: number[]) {
      const excludedAttributes = this.excludedCategorizedStyleAttributes(layerIds);
      this.featuresAtClick = await getIntersectingFeatures({
        geometry: polygon,
        layerIds,
        planIds: this.planIds,
        excludedAttributes,
      });
    },
    async handleFeatureClickLaxCommentParent(polygon: Polygon) {
      await this.fetchFeaturesAtClick(
        polygon,
        _uniq([...this.activeLayerIds, ...this.commentableLayerIds]).filter((lid) => lid),
      );

      if (this.featureOptions.length < 1) {
        this.handleAddComment();
      } else {
        this.showPopup = true;
      }
    },
    async handleFeatureClickStrictCommentParent(polygon: Polygon) {
      await this.fetchFeaturesAtClick(polygon, this.activeLayerIds);

      if (this.featureOptions.length === 1) {
        this.handleFeatureSelection(this.featuresAtClick[0]);
      } else if (this.featureOptions.length > 1) {
        this.showPopup = true;
      }
    },
    handleFeatureSelection(feature: Feature | null) {
      this.setSelectedFeature({
        feature,
        surveyEnabledSelection: this.allowCommentOnSelectedFeature,
      });
      this.setFeaturesAtFocusPoint(this.featuresAtClick);
      this.resetActiveLocation();
      this.showPopup = false;
    },
    selectAllFeatures() {
      this.$emit('apply-selection-change', {
        featuresToSelect: this.featuresAtClick,
        featuresToDeselect: [],
        isNotApplied: false,
      });
      this.showPopup = false;
    },
    deselectAllFeatures() {
      this.$emit('apply-selection-change', {
        featuresToSelect: [],
        featuresToDeselect: this.featuresAtClick,
        isNotApplied: false,
      });
      this.showPopup = false;
    },
    async handleAddComment() {
      // In the feature disambiguation menu, we display features intersecting with some radius
      // around the exact clicked point. If the user chooses to add a comment without selecting
      // a feature, we want to display in the survey's parent selection dropdown only those
      // features that exactly intersect with the clicked point.
      const features = this.featuresAtClick.filter((feature) =>
        feature.geometry && this.mapClickCoordinates
          ? booleanIntersects(feature.geometry, pointFromPosition(this.mapClickCoordinates))
          : false,
      );

      await this.setSelectedFeature({ feature: null, shouldDisplayAndHighlight: false });
      await this.setFeaturesAtFocusPoint(features);
      await this.setActiveLocation({ coordinates: this.mapClickCoordinates, shouldJump: false });
      await this.setSurveyActive(true);
      this.showPopup = false;
    },
  },
});
