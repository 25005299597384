





















import ArrowRightIcon from '@/assets/icons/arrow-right.svg?inline';
import ArrowLeftIcon from '@/assets/icons/arrow-left.svg?inline';
import Vue from 'vue';

export default Vue.extend({
  name: 'pagination-header',
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
  },
  props: {
    itemCount: { type: Number, required: true },
    activeIndex: { type: Number, required: true },
  },
});
