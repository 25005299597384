















import Vue, { PropType } from 'vue';
import { Feature, InputBlockConfig } from '@/types';
import ValidatedAttributeInput from '@/components/side-bar/content/blocks/sub-components/ValidatedAttributeInput.vue';

export default Vue.extend({
  name: 'single-line-text-input-block',
  components: { ValidatedAttributeInput },
  props: {
    config: { type: Object as PropType<InputBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    validationMethods: { type: Object, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
});
