
















import Vue, { PropType } from 'vue';
import { CheckboxGroupBlockConfig, InputBlockConfig, Feature } from '@/types';
import ValidatedAttributeInput from '@/components/side-bar/content/blocks/sub-components/ValidatedAttributeInput.vue';

export default Vue.extend({
  name: 'checkbox-group-input-block',
  props: {
    config: { type: Object as PropType<CheckboxGroupBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    validationMethods: { type: Object, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  components: { ValidatedAttributeInput },
  computed: {
    inputBlockConfig(): InputBlockConfig {
      return {
        type: this.config.type,
        key: this.config.key,
        label: this.config.label,
        attribute: this.config.attribute,
        required: this.config.required,
        defaultValue: this.config.defaultValue,
      };
    },
  },
});
