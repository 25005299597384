














































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { SIDEBAR_SPINNER, SIDEBAR_SUBHEADER_TEXT } from '@/constants';
import Search from '@/components/search/Search.vue';
import { ContentBlockConfig } from '@/types';
import SidebarContent from './content/SidebarContent.vue';
import SidebarFooter from './SidebarFooter.vue';
import SidebarToggleButton from './SidebarToggleButton.vue';
import CommentFeed from './content/comments/CommentFeed.vue';

interface SidebarData {
  SIDEBAR_SPINNER: string;
  termsAreAccepted: boolean;
  showHeaderContent: boolean;
}

const defaultSidebarContent = `
  <h5>${SIDEBAR_SUBHEADER_TEXT}<h5/>
`;

export default Vue.extend({
  name: 'sidebar',
  components: {
    Search,
    SidebarContent,
    SidebarFooter,
    SidebarToggleButton,
    CommentFeed,
  },
  props: {
    sidebarIsActive: { type: Boolean, required: true },
  },
  data(): SidebarData {
    return {
      SIDEBAR_SPINNER,
      termsAreAccepted: false,
      showHeaderContent: true,
    };
  },
  computed: {
    ...mapGetters('config', ['getViewAttributes', 'canViewSubmittedComments', 'isPrivateView']),
    ...mapState('sidebar', ['surveyData', 'surveyActive']),
    ...mapState('features', ['selectedFeatures']),
    ...mapState('config', ['viewAttributes']),
    ...mapGetters('comments', ['commentParentPairs']),
    ...mapGetters('user', ['userInfo']),
    initiallyExpandSubmittedComments(): boolean {
      const { initiallyExpandSubmittedComments } = this.getViewAttributes?.sidebarConfig;
      return initiallyExpandSubmittedComments === undefined
        ? true
        : initiallyExpandSubmittedComments;
    },
    sidebarContentBlocks(): ContentBlockConfig[] {
      return this.getViewAttributes?.sidebarConfig?.contentBlocks || [];
    },
    sidebarHeaderContent(): string {
      return this.getViewAttributes?.sidebarConfig?.sidebarHeaderContent || defaultSidebarContent;
    },
    footerContent(): string {
      return this.getViewAttributes?.sidebarConfig?.footerContent || '';
    },
    isHeaderContentCollapsible(): boolean {
      return this.canViewSubmittedComments;
    },
    searchPlaceholder(): string {
      return this.getViewAttributes?.searchPlaceholder;
    },
  },
});
