















import Vue, { PropType } from 'vue';
import { mapActions, mapState } from 'vuex';
import { required, sameAs, maxLength } from 'vuelidate/lib/validators';
import { ContentBlockTypes, Feature, InputBlockConfig } from '@/types';
import _omitBy from 'lodash.omitby';
import _isNil from 'lodash.isnil';

import ValidatedInput from '@/components/side-bar/content/blocks/sub-components/ValidatedInput.vue';

/**
 * The validated Attribute input is a wrapper for a basic input element. It governs all necessary functionality
 * for linking an input to the attribute of a vetro feature (parentFeature) and storing that attribute value in
 * a Vuex store. Additionally, this component accepts a set of validation functions which will be evaluated against
 * the contents of the child input whenever the input is changed. The final validation state of this component is
 * then propagated to a validation section of the Vuex store, to be accessed by a parent form component.
 */
export default Vue.extend({
  name: 'validated-attribute-input',
  props: {
    config: { type: Object as PropType<InputBlockConfig>, required: true },
    options: { type: Array as PropType<string[]>, required: false },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    uncheckedValue: { type: [String, Boolean, Number], required: false },
    characterLimit: { type: Number, required: false },
    validationMethods: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    inputType: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  data(): { value: string | number | boolean | undefined | unknown[] } {
    return {
      value: undefined,
    };
  },
  validations() {
    /*
    Right now validations are a mix of hard-coded and configured. Ideally all
    the validations would be passed in the same way, either through the config
    or as props, but right now configs only support a boolean required field
    so we manually convert that to a validation object.
     */

    const isRequired = this.config.required;

    // if this is a required checkbox, we want to ensure it is checked,
    // but required will count false as valid, so use same as to ensure
    // it is true. We still need the required validator since we check
    // this.v.$params.required in validatedInput to display the red *
    const isCheckbox = this.config.type === ContentBlockTypes.CheckboxInput;
    const isRequiredCheckbox = isRequired && isCheckbox;

    const configuredValidations = _omitBy(
      {
        sameAs: isRequiredCheckbox ? sameAs(() => true) : null,
        required: isRequired ? required : null,
        maxLength: this.characterLimit ? maxLength(this.characterLimit) : null,
      },
      _isNil,
    );

    return {
      value: {
        ...this.validationMethods,
        ...configuredValidations,
      },
    };
  },
  components: {
    ValidatedInput,
  },
  computed: {
    ...mapState('sidebar', ['surveyData']),
    dbAttributeName(): string {
      return this.config.attribute;
    },
    formLabel(): string {
      return this.config.label;
    },
    initialValue(): string | number | boolean | undefined | unknown[] {
      // TODO: For radios / selects, maybe add an entry for the default value if it does not match any option?
      return this.parentFeature?.properties[this.dbAttributeName] ?? this.config.defaultValue;
    },
    // The name used to store this data in Vuex
    internalName(): string {
      return this.config.attribute;
    },
  },
  watch: {
    // This watcher keeps the underlying inputs in sync with surveyData
    surveyData: {
      deep: true,
      handler() {
        const surveyValue = this.surveyData[this.internalName];
        if (this.value !== surveyValue) {
          this.value = surveyValue;
        }
        this.updateFormValidity();
      },
    },
    async value() {
      await this.updateFormValidity();
      this.updateSurveyData();
    },
    initialValue(newVal) {
      this.value = newVal;
    },
  },
  methods: {
    ...mapActions('sidebar', ['setSurveyData', 'setFormValidity']),
    updateSurveyData() {
      this.setSurveyData({
        key: this.internalName,
        value: this.value,
      });
    },
    updateFormValidity(): Promise<void> {
      return this.setFormValidity({ key: this.internalName, isValid: !this.$v.$invalid });
    },
  },
  mounted() {
    this.updateFormValidity();
    this.value = this.initialValue;
  },
});
