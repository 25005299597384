








































































































import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { paramCase } from 'change-case';
import { Validation } from 'vuelidate';
import { BootstrapOptions } from '@/types';
import CharacterCounter from './CharacterCounter.vue';
import FileSelect from './FileSelect.vue';

/**
 * The Validated input is a simple wrapper for a bootstrap input / select. Its primary concern is to determine how to
 * render based on its passed validation state (v). It is otherwise a completely controlled component, and should
 * generally be controlled by a more integrated component (like ValidatedAttributeInput).
 *
 * It just so happens that text inputs and select form elements are similar enough that we don't need any code that is
 * specific to either one. If that changes, we may need a separate component for it.
 */
export default Vue.extend({
  name: 'validated-input',
  components: {
    CharacterCounter,
    FileSelect,
  },
  props: {
    formLabel: { type: String, required: false },
    options: { type: Array as PropType<BootstrapOptions>, required: false },
    internalName: { type: String, required: true },
    value: {
      // NOTE: This can be a File for our file input, but trying to type it that way
      // causes all sorts of mayhem with the Vue Typescript checker, so I am leaving it out
      // You will see PropType validation errors when running this locally -- those can be
      //  ignored as long as they are receiving a File object and not something else unexpected.
      // Perhaps a newer version of Vue 2 will improve this.
      type: [String, Number, Boolean, Array],
      required: false,
    },
    v: { type: Object as PropType<Validation>, required: true },
    inputType: { type: String, required: false },
    uncheckedValue: { type: [String, Boolean, Number], required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  computed: {
    ...mapState('sidebar', ['formInErrorState']),
    inputId(): string {
      return `input-${paramCase(this.internalName)}`;
    },
    state(): boolean | null {
      // Show invalid if touched and invalid, otherwise show nothing. Do NOT show green valid state (return:true)
      if (this.formInErrorState) {
        return this.v.$invalid ? false : null;
      }
      return this.v.$dirty && this.v.$invalid ? false : null;
    },
    maxInputLength(): number | null {
      return this.v.$params.maxLength?.max ?? null;
    },
    invalidMessage(): string {
      return this.maxInputLength && (this.value as string)?.length > this.maxInputLength
        ? `Please limit your input to ${this.maxInputLength} characters`
        : 'Please provide a valid value';
    },
    computedModel: {
      get(): string | number | boolean | undefined | unknown[] | File {
        return this.value;
      },
      set(value: string | number | boolean | undefined | unknown[] | File): void {
        this.v.$touch();
        this.$emit('input', value);
      },
    },
  },
  methods: {
    // Allow deselecting radio buttons!
    radioBind(event: Event) {
      const target = event.target as HTMLInputElement;
      target.onclick = this.emitRepeatClick;
    },
    emitRepeatClick() {
      this.$emit('input', undefined);
    },
    setFile(e: File) {
      this.computedModel = e;
    },
  },
});
