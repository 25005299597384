



























































import { mapState, mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import {
  VetroMap,
  addBufferToBounds,
  VetroFeatureLayer,
  getCategorizedFilterExpression,
  highlightFeatures,
  getHighlightingFeature,
  vetroLayerIdToMapboxLayerId,
} from '@nbtsolutions/vetro-mapbox';
import turfBBox from '@turf/bbox';
import {
  Feature as TurfFeature,
  featureCollection as turfFeatureCollection,
  Geometry,
  Properties,
} from '@turf/helpers';
import { Map, CameraOptions } from 'mapbox-gl';
import { MglMarker } from 'v-mapbox';
import _debounce from 'lodash.debounce';
import _castArray from 'lodash.castarray';
import bboxPolygon from '@turf/bbox-polygon';
import { Layer } from '@/types/layers';
import { DEFAULT_JUMP_ZOOM, MapboxBasemap, MAP_ACTION_TYPE, SELECTION_MODES } from '@/constants';
import { Feature, BBox, MapAction, NullableGeometry } from '@/types';
import MapMarkerIcon from '@/assets/icons/map-marker.svg?inline';
import { getMapSidebarPadding } from '@/util/map';
import { updateUrlMapLocation } from '@/util/urlAttributesParser';
import FeatureSelectionManager from './map/FeatureSelection/FeatureSelectionManager.vue';
import CommentClusters from './map/CommentClusters.vue';
import FeatureSelectionButtons from './map/FeatureSelection/FeatureSelectionButtons.vue';

const AVAILABLE_MAPBOX_BASEMAPS = Object.values(MapboxBasemap);

export default Vue.extend({
  name: 'map-container',
  components: {
    VetroMap,
    VetroFeatureLayer,
    MglMarker,
    MapMarkerIcon,
    FeatureSelectionManager,
    CommentClusters,
    FeatureSelectionButtons,
  },
  data() {
    return {
      selectionMode: SELECTION_MODES.CLICK,
      mapHasLoaded: false,
    };
  },
  created() {
    this.handleCommentFetch = _debounce(this.handleCommentFetch, 200);
  },
  computed: {
    ...mapGetters('layers', ['mapboxToVetroLayerIdMap', 'attributeStatusMapByLayerId']),
    ...mapGetters('config', ['getViewAttributes', 'isFeatureSelectionEnabled']),
    ...mapState('config', ['planIds', 'tileServers', 'publicApiToken', 'viewAttributes']),
    ...mapState('map', ['mapPositionResetFlag', 'activeLocation', 'mapAction']),
    ...mapState('features', ['selectedFeatures', 'shouldDisplayAndHighlightSelectedFeature']),
    ...mapState('sidebar', ['sidebarIsActive']),
    ...mapGetters('layers', [
      'sortedLayers',
      'activeLayerIds',
      'layerIdGeomTypeMap',
      'layerIdFeatureTableMap',
    ]),
    ...mapGetters('config', ['canViewSubmittedComments', 'useImperialUnits']),
    ...mapState('comments', ['showCommentsOnMap']),

    // Check for feature flag
    multiSelectFlag(): boolean {
      return this.getViewAttributes.multiSelect;
    },
    selectedFeatureId(): string {
      return this.selectedFeatures[0]?.xVetro?.vetroId;
    },
    availableBasemaps() {
      const mapboxBasemaps = AVAILABLE_MAPBOX_BASEMAPS.map((name) => ({ name, disabled: false }));
      return [...mapboxBasemaps];
    },
    mapAccessToken(): string {
      return process.env.VUE_APP_MAPBOX_TOKEN;
    },
    mapLayers(): Layer & { visible: boolean } {
      return this.sortedLayers.map((layer: Layer) => ({
        ...layer,
        visible: this.activeLayerIds.includes(layer.id),
      }));
    },
    initialBasemap(): MapboxBasemap {
      return this.getViewAttributes?.initialBasemap || AVAILABLE_MAPBOX_BASEMAPS[0];
    },
    labelLayerMinZoom(): number {
      return this.viewAttributes?.labelLayerMinZoom || 13;
    },
    maxBounds(): [number, number][] | undefined {
      const maxBounds = this.getViewAttributes?.maxBounds;
      if (!maxBounds) return undefined;
      let southwestLng = maxBounds.southwest?.lng || -180;
      let southwestLat = maxBounds.southwest?.lat || -90;
      let northeastLng = maxBounds.northeast?.lng || 180;
      let northeastLat = maxBounds.northeast?.lat || 90;
      if (southwestLng > northeastLng) {
        [southwestLng, northeastLng] = [northeastLng, southwestLng];
      }
      if (southwestLat > northeastLat) {
        [southwestLat, northeastLat] = [northeastLat, southwestLat];
      }
      return [
        [southwestLng, southwestLat],
        [northeastLng, northeastLat],
      ];
    },
    defaultCenter(): [number, number] | undefined {
      const initialMapCenter = this.viewAttributes?.initialMapCenter;
      return initialMapCenter
        ? (Object.values(initialMapCenter).reverse() as [number, number])
        : undefined;
    },
    defaultZoom(): number | undefined {
      return this.getViewAttributes?.initialMapZoom;
    },
    shouldDisplayMarker(): boolean {
      return this.activeLocation?.length > 0;
    },
    allowFeatureSelection(): boolean {
      return this.isFeatureSelectionEnabled && this.mapHasLoaded;
    },
    selectedHighlightFeatures(): {
      properties: Record<string, string>;
      geometry: NullableGeometry;
      type: string;
    } {
      return this.selectedFeatures
        .map((feature: Feature) => ({
          feature,
        }))
        .filter((feature: Feature) => feature)
        .map(
          ({
            feature,
            color,
            opacity,
            fillColor,
          }: {
            feature: Feature;
            color: string;
            opacity: number;
            fillColor: string;
          }) => getHighlightingFeature(feature, color, opacity, fillColor),
        );
    },
    sortedMapboxLayerIds(): Array<string> {
      return this.sortedLayers
        .map((l: { id: number }) => {
          return vetroLayerIdToMapboxLayerId(l.id, this.layerIdFeatureTableMap);
        })
        .filter((mapboxLayer: string) => !!mapboxLayer);
    },
  },
  watch: {
    mapPositionResetFlag(flag): void {
      if (flag) {
        this.jump({ center: this.defaultCenter, zoom: this.defaultZoom });
        this.unsetMapPositionResetFlag();
      }
    },
    sidebarIsActive(): void {
      // Just adjusts the map padding compensation when the sidebar is toggled
      this.jump();
    },
    selectedFeatures(): void {
      if (this.canViewSubmittedComments && !this.selectedFeatures[0]) {
        this.handleCommentFetch();
      }
      if (
        this.shouldDisplayAndHighlightSelectedFeature &&
        this.selectedFeatures.length === 1 &&
        this.selectionMode === SELECTION_MODES.CLICK
      ) {
        this.setPolygonIntersectionRelationships({
          coordinates: this.selectedFeatures[0].geometry.coordinates,
        });
        this.goToFeatures(this.selectedFeatures);
      }
      highlightFeatures({
        features: this.selectedHighlightFeatures,
        map: this.map,
        sortedMapboxLayerIds: this.sortedMapboxLayerIds,
        layerIdGeomTypeMap: this.layerIdGeomTypeMap,
      });
    },
    mapAction(action: MapAction) {
      const { type, watcherHitCallback = () => {} } = action;

      if (type === MAP_ACTION_TYPE.JUMP) {
        const { coordinates } = action.payload;
        this.jump({ center: coordinates, zoom: DEFAULT_JUMP_ZOOM });
      }

      watcherHitCallback();
    },
  },
  methods: {
    ...mapActions('polygonIntersections', ['setPolygonIntersectionRelationships']),
    ...mapActions('map', ['unsetMapPositionResetFlag', 'setMapPositionResetFlag']),
    ...mapActions('comments', ['fetchCommentsInPolygon', 'setShowCommentsOnMap']),
    ...mapActions('features', ['applySelectionChange', 'resetFeatureState']),
    ...mapActions('sidebar', ['setSurveyActive', 'resetSidebarState']),
    updateSelectedFeatures(currentSelection: {
      featuresToSelect: Array<Feature<NullableGeometry>>;
      featuresToDeselect: Array<Feature<NullableGeometry>>;
      isNotApplied: boolean;
    }) {
      this.applySelectionChange(currentSelection);
    },
    getMapboxId(layer: Layer): string | null {
      const layerMap = Object.entries(this.mapboxToVetroLayerIdMap).find(
        ([__, layerId]) => layerId === layer.id,
      );
      if (layerMap) {
        return layerMap[0];
      }

      return null;
    },
    planLayerAttributeFilter(layer: Layer): Array<unknown> {
      if (this.planIds.length < 1) return [];

      const filter: Array<unknown> = ['all', ['in', ['get', 'plan_id'], ['literal', this.planIds]]];

      const attributeStatusMap = this.attributeStatusMapByLayerId(layer.id);

      const { categorizedAttributeLabel } = layer.style;

      if (categorizedAttributeLabel) {
        filter.push(getCategorizedFilterExpression(layer, attributeStatusMap));
      }

      return filter;
    },
    onMapLoaded(map: Map): void {
      this.map = map;
      this.mapHasLoaded = true;
      // Compensate for the sidebar width in the default map center.
      this.setMapPositionResetFlag();

      this.map.on('moveend', () => {
        if (this.canViewSubmittedComments) {
          this.handleCommentFetch();
        }
        if (this.viewAttributes.keepUrlUpdated) {
          updateUrlMapLocation(this.map);
        }
      });

      // Add jump method to window for use with UserFlows
      window.mapJumpTo = this.jump;

      window.map = map;
    },
    jump(options: CameraOptions = {}) {
      if (this.map) {
        this.map.jumpTo({
          ...options,
          padding: getMapSidebarPadding(this.sidebarIsActive),
        });
      }
    },
    goToFeatures(
      features: Array<TurfFeature<Geometry, Properties>> | TurfFeature<Geometry, Properties>,
    ) {
      const featuresToZoomTo = _castArray(features);
      const someHaveGeometry = featuresToZoomTo.some(
        (feature: TurfFeature<Geometry, Properties>) => feature.geometry,
      );

      if (!someHaveGeometry || !this.map) return;
      const bufferedBbox = addBufferToBounds(turfBBox(turfFeatureCollection(featuresToZoomTo)));
      this.map.fitBounds(bufferedBbox, {
        linear: true,
      });
    },
    handleCommentFetch() {
      if (this.map && this.selectedFeatures.length === 0) {
        const bbox = this.map.getBounds().toArray().flat() as BBox;
        const { geometry } = bboxPolygon(bbox);
        this.fetchCommentsInPolygon(geometry);
      }
    },
    handleSelectionButtons(mode: string) {
      this.selectionMode = mode;
    },
    onMapStyleChanged() {
      this.setShowCommentsOnMap(false);
      this.$nextTick(() => {
        this.setShowCommentsOnMap(true);
      });
    },
    clearSelectedFeatures() {
      this.setSurveyActive(false);
      this.resetFeatureState();
      this.resetSidebarState();
    },
  },
});
