























import Vue from 'vue';

export default Vue.extend({
  props: {
    quantity: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fontSize(): number {
      const numDigits = this.getNumberOfDigits(this.quantity);
      if (numDigits === 1) return 12;
      return 14 - numDigits * 2;
    },
    style(): string {
      return `font-size: ${this.fontSize}px`;
    },
  },
  methods: {
    getNumberOfDigits(num: number): number {
      return num.toString().length;
    },
  },
});
