<script>
import Vue from 'vue';
import _difference from 'lodash.difference';
import { mapGetters } from 'vuex';
import { setupDraw } from './utils/setupDraw';

export default Vue.extend({
  name: 'mapbox-draw-component',
  inject: {
    map: {
      default: null,
    },
  },
  props: {
    activePlanIds: { type: Array, required: true },
    activeLayerIds: { type: Set, required: true },
    drawPreCacheParams: {
      type: Object,
      required: true,
    },
    activeMode: { type: String, required: true },
    draw: {
      validator(val) {
        return typeof val === 'object'; // includes null
      },
      required: true,
    },
  },
  watch: {
    drawPreCacheParams() {
      const { layerIds, planIds } = this.drawPreCacheParams;

      this.fetchMapExtentGeometryIfModifyingGeom(planIds, layerIds);
    },
    // activeMode and drawModeOptions are updated at the same time
    // in the drawing/setDrawingMode action. We call
    // the debounced function with watcher to ensure that we only
    // respond once to the update if both are changed.
    activeMode: 'handleActiveModeUpdate',
    activePlanIds: 'handleActivePlanIdUpdate',
    activeLayerIds: 'handleActiveLayerIdUpdate',
  },
  mounted() {
    this.$emit('load', setupDraw());
  },
  beforeDestroy() {
    this.$emit('load', null);
  },
  methods: {
    handleActiveModeUpdate(newMode, oldMode) {
      const { STATIC } = this.draw.modes;

      if (newMode === STATIC) {
        if (this.draw && this.draw.resetSnappingGeomCache) {
          this.draw.resetSnappingGeomCache();
        }
      } else if (oldMode === STATIC) {
        this.fetchMapExtentGeometryIfModifyingGeom(this.activePlanIds, [...this.activeLayerIds]);
      }
    },
    handleActivePlanIdUpdate(newPlanIds, oldPlanIds) {
      const added = _difference(newPlanIds, oldPlanIds);

      if (added.length === 0) return;

      this.fetchMapExtentGeometryIfModifyingGeom(added, [...this.activeLayerIds]);
    },
    handleActiveLayerIdUpdate(newLayerIds, oldLayerIds) {
      const added = _difference(newLayerIds, oldLayerIds);

      if (added.length === 0) return;

      this.fetchMapExtentGeometryIfModifyingGeom(this.activePlanIds, added);
    },
    fetchMapExtentGeometryIfModifyingGeom(planIds, layerIds) {
      if (this.draw && this.draw.fetchMapExtentGeometry && this.isModifyingGeom) {
        this.draw.fetchMapExtentGeometry(
          planIds,
          layerIds,
          this.excludedCategorizedStyleAttributes(layerIds),
        );
      }
    },
  },
  computed: {
    ...mapGetters('layers', ['excludedCategorizedStyleAttributes']),
    isModifyingGeom() {
      if (this.activeMode) {
        return this.activeMode.includes('draw_') || this.activeMode.includes('_select');
      }
      return false;
    },
  },
  render() {
    return null;
  },
});
</script>
