










import Vue, { PropType } from 'vue';
import { ValidationRule, email } from 'vuelidate/lib/validators';
import { Feature, InputBlockConfig } from '@/types';
import SingleLineTextInputBlock from './SingleLineTextInputBlock.vue';

export default Vue.extend({
  name: 'email-input-block',
  components: { SingleLineTextInputBlock },
  props: {
    config: { type: Object as PropType<InputBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  computed: {
    isValidEmail(): ValidationRule {
      // Vuelidate's email validator has incorrect return type; does not return a ValidationRule, instead returns boolean.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return email;
    },
  },
});
