















import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'image-carousel',
  props: {
    images: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentSlideIndexModel: {
      get(): number {
        return this.value;
      },
      set(newSlideIndex: number): void {
        this.$emit('input', newSlideIndex);
      },
    },
  },
});
