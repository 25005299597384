














import Vue from 'vue';

export default Vue.extend({
  name: 'collapse-toggle-button',
  props: {
    collapsed: { type: Boolean, required: true },
  },
  methods: {
    onToggle() {
      this.$emit('toggle');
    },
  },
});
