




















import Vue from 'vue';
import { mapGetters } from 'vuex';

interface FileSelectData {
  file: File | null;
  fileSizeExceeded: boolean;
}

// Allow us to assume the b-form-file will have reset method for clearing file
interface BootstrapFileInput {
  reset: () => unknown;
}

const mbToBytes = (n: number): number => n * 10 ** 6;

export default Vue.extend({
  name: 'file-select',
  props: {
    state: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    maxSize: {
      type: Number,
      default: 20,
    },
  },
  data(): FileSelectData {
    return {
      file: null,
      fileSizeExceeded: false,
    };
  },
  computed: {
    ...mapGetters('config', ['isPrivateView']),
    isDisabled(): boolean {
      return this.disabled || !this.isPrivateView;
    },
    placeholder(): string {
      return this?.isPrivateView
        ? `Choose a file no larger than ${this.maxSize}MB`
        : 'File upload not available in public views';
    },
    fileSizeValid(): boolean {
      return !!(this.file && this.file.size < mbToBytes(this.maxSize));
    },
  },
  methods: {
    setFile(e: File) {
      // We will clear the file and show an error if the file is too large
      if (this.file && !this.fileSizeValid) {
        this.fileSizeExceeded = true;

        // TODO: find better way to clear the file
        const fileSelectRef = this.$refs.fileInput as unknown as BootstrapFileInput;
        if (fileSelectRef?.reset) fileSelectRef.reset();
        return;
      }
      this.fileSizeExceeded = false;
      this.$emit('set-file', e);
    },
  },
});
