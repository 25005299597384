interface MeasurementMsg {
  msgType: 'measurement';
  speed: number;
  test: 'download' | 'upload';
}

interface ErrorMsg {
  msgType: 'error';
  error: string;
}

interface CompleteMsg {
  msgType: 'complete';
}

export interface CallbackFns {
  measurement: (x: MeasurementMsg) => void;
  error: (x: ErrorMsg) => void;
  complete: (x: CompleteMsg) => void;
}

const getDownloadWorker = () => new Worker(`./workers/download-worker.js`, { type: 'module' });
const getUploadWorker = () => new Worker(`./workers/upload-worker.js`, { type: 'module' });

const doSpeedTest = async (callbacks: CallbackFns): Promise<() => void> => {
  const response = await fetch('https://locate.measurementlab.net/v2/nearest/ndt/ndt7');
  const {
    results: [choice],
  } = await response.json();

  const downloadWorker = getDownloadWorker();
  const uploadWorker = getUploadWorker();

  const workerAndUrlPairs = [
    { url: choice.urls['wss:///ndt/v7/download'], worker: downloadWorker },
    { url: choice.urls['wss:///ndt/v7/upload'], worker: uploadWorker },
  ];

  workerAndUrlPairs.forEach(({ worker, url }) => {
    worker.onmessage = (ev) => {
      if (!ev.data || !ev.data.msgType || ev.data.msgType === 'error') {
        const msg = !ev.data ? `Unknown error with speed test worker` : ev.data.Error;
        callbacks.error({
          msgType: 'error',
          error: msg,
        });
      } else if (ev.data.msgType === 'complete') {
        callbacks.complete(ev.data);
      } else if (ev.data.msgType === 'measurement') {
        callbacks.measurement(ev.data);
      }
    };
    // Start the worker.
    worker.postMessage(url);
  });

  const terminate = () => {
    downloadWorker.terminate();
    uploadWorker.terminate();
  };
  return terminate;
};

export default doSpeedTest;
