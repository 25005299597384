<template>
  <VueSvgGauge
    :start-angle="-90"
    :end-angle="90"
    :value="Number(value)"
    :separator-step="10"
    :separator-thickness="1"
    :innerRadius="90"
    :min="0"
    :max="200"
    :gauge-color="formattedColors"
    :scale-interval="0"
  >
    <div class="position-relative w-100 h-100 speed-test-text-container">
      <span class="position-absolute d-flex justify-content-center delimeter-0">0</span>
      <span class="position-absolute d-flex justify-content-center delimeter-1">40</span>
      <span class="position-absolute d-flex justify-content-center delimeter-2">80</span>
      <span class="position-absolute d-flex justify-content-center delimeter-3">120</span>
      <span class="position-absolute d-flex justify-content-center delimeter-4">160</span>
      <span class="position-absolute d-flex justify-content-center delimeter-5">200</span>
      <div class="position-absolute w-100 d-flex justify-content-center central-value">
        {{ value }}
      </div>
    </div>
  </VueSvgGauge>
</template>
<script>
import Vue from 'vue';
import { VueSvgGauge } from 'vue-svg-gauge';

export default Vue.extend({
  components: {
    VueSvgGauge,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    gaugeColors: { type: [String, Array], default: 'green' },
  },
  computed: {
    formattedColors() {
      const colorsArray = [this.gaugeColors].flat();
      const numCols = colorsArray.length;
      if (numCols === 1) return colorsArray[0];
      return colorsArray.map((color, i) => ({
        offset: Math.floor((i / (numCols - 1)) * 100),
        color,
      }));
    },
  },
  mounted() {
    const speedTestContainers = document.getElementsByClassName('speed-test-text-container');
    Array.from(speedTestContainers).forEach((el) => {
      if (el.parentElement && el.parentElement.parentElement) {
        el.parentElement.style.overflow = 'visible';
        el.parentElement.parentElement.style.overflow = 'visible';
      }
    });
  },
});
</script>
<style scoped lang="scss">
.text-container {
  overflow: visible !important;
}
.central-value {
  bottom: 0;
  font-size: 30px;
  font-weight: 900;
}
// I do love me a good math opportunity.
// Let x be the angle the delimeter text makes with the bottom center of the gauge
// left percentage value: 100 * (1 - Math.cos(x)) / 2
// left pixel value: 15 * Math.cos(x) + 15   (15 is half the width of the span. 15 is also the magnitude of my desired radially outward shift)
// top percentage value: 100 *(1 - Math.sin(x))
// top pixel value: 15 * Math.sin(x) + 10    (10 is half the height of the span. 15 is the magnitude of my desired radially outward shift)
.delimeter-0 {
  width: 30px;
  left: -30px;
  top: calc(100% - 10px);
}
.delimeter-1 {
  width: 30px;
  left: calc(9.5% - 27.1px);
  top: calc(41.2% - 18.8px);
}
.delimeter-2 {
  width: 30px;
  left: calc(34.5% - 19.6px);
  top: calc(4.8% - 24.3px);
}
.delimeter-3 {
  width: 30px;
  left: calc(65.4% - 10.4px);
  top: calc(4.9% - 24.26px);
}
.delimeter-4 {
  width: 30px;
  left: calc(90.4% - 2.9px);
  top: calc(41.2% - 18.9px);
}
.delimeter-5 {
  width: 30px;
  left: 100%;
  top: calc(100% - 10px);
}
</style>
