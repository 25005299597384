




















































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import { COMMENT_SPINNER, MAP_ACTION_TYPE } from '@/constants';
import { ParentChildPair, Feature } from '@/types';
import InfoIcon from '@/assets/icons/info.svg?inline';
import RightArrowIcon from '@/assets/icons/arrow-right.svg?inline';
import DownArrowIcon from '@/assets/icons/arrow-down.svg?inline';
import Comment from './Comment.vue';

interface CommentFeedData {
  expanded: boolean;
  COMMENT_SPINNER: string;
}

export default Vue.extend({
  name: 'comment-feed',
  components: {
    InfoIcon,
    RightArrowIcon,
    DownArrowIcon,
    Comment,
  },
  props: {
    allowZoomToParent: { type: Boolean, default: false },
    showOnMapToggle: { type: Boolean, default: false },
    commentParentPairs: { type: Array as PropType<ParentChildPair[]>, default: () => [] },
    initiallyExpand: { type: Boolean, default: true },
  },
  data(): CommentFeedData {
    return {
      expanded: this.initiallyExpand,
      COMMENT_SPINNER,
    };
  },
  beforeDestroy(): void {
    this.setShowCommentsOnMap(false);
  },
  watch: {
    initiallyExpand(value) {
      this.expanded = value;
    },
  },
  computed: {
    ...mapState('comments', ['showCommentsOnMap']),
    ...mapGetters('spinners', { spinnerIdIsActive: 'isActive' }),
    ...mapGetters('comments', ['hoveredCommentIds']),
    isActive(): boolean {
      return this.spinnerIdIsActive(COMMENT_SPINNER);
    },
    filteredAndSortedCommentParentPairs(): ParentChildPair[] {
      return [...this.commentParentPairs].sort((a, b) => {
        const aXvetro = a.child.xVetro;
        const bXvetro = b.child.xVetro;

        const { createdTime: aCT, vetroId: aId } = aXvetro;
        const { createdTime: bCT, vetroId: bId } = bXvetro;

        const aHovered = this.hoveredCommentIds.has(aId);
        const bHovered = this.hoveredCommentIds.has(bId);

        if (aHovered && !bHovered) return -1;
        if (!aHovered && bHovered) return 1;

        return dayjs(bCT).unix() - dayjs(aCT).unix();
      });
    },
  },
  methods: {
    ...mapActions('features', ['setSelectedFeature']),
    ...mapActions('comments', ['setShowCommentsOnMap']),
    ...mapActions('map', ['triggerMapAction']),
    commentIsBeingHovered(comment: Feature) {
      return this.hoveredCommentIds.has(comment.xVetro.vetroId);
    },
    jumpToComment(comment: Feature, parent: Feature | null) {
      if (parent) {
        this.setSelectedFeature({ feature: parent });
      } else {
        this.triggerMapAction({
          type: MAP_ACTION_TYPE.JUMP,
          payload: { coordinates: comment.geometry?.coordinates },
        });
      }
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
});
