
















import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { isMobile } from '@/util/mobile';
import Sidebar from './side-bar/Sidebar.vue';
import LayerLegend from './layer-legend/LayerLegend.vue';

export default Vue.extend({
  name: 'overlay-container',
  components: {
    Sidebar,
    LayerLegend,
  },

  computed: {
    ...mapState('sidebar', ['sidebarIsActive']),
    ...mapState('legend', ['legendIsActive']),
    ...mapState('config', ['readOnlyMode']),
    ...mapGetters('legend', ['isLegendEnabled']),
    ...mapGetters('config', ['getViewAttributes']),
    closeSidebarInitially(): boolean {
      return this.getViewAttributes?.sidebarConfig?.closeSidebarInitially ?? false;
    },
    openLegendInitially(): boolean {
      return this.getViewAttributes?.sidebarConfig?.openLegendInitially ?? false;
    },
    bannerActive(): boolean {
      return this.readOnlyMode;
    },
  },
  methods: {
    ...mapActions('sidebar', ['toggleSidebar']),
    ...mapActions('legend', ['toggleLegend']),
    handleSidebarToggle() {
      this.toggleSidebar();

      if (this.sidebarIsActive && isMobile(window.innerWidth) && this.legendIsActive)
        this.toggleLegend();
    },
  },
  mounted() {
    if (this.closeSidebarInitially && this.sidebarIsActive) {
      this.toggleSidebar();
    }
    if (this.openLegendInitially && !isMobile(window.innerWidth) && !this.legendIsActive) {
      this.toggleLegend();
    }
  },
});
