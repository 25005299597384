










import Vue from 'vue';

export default Vue.extend({
  name: 'character-counter',
  props: {
    textLength: { type: Number, required: true },
    maxLength: { type: Number, required: true },
  },
});
