
































import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { Feature, LayerSymbol } from '@/types';
import dayjs from 'dayjs';
import { getFeatureDefaultSymbolMap } from '@/util/features';
import VetroIcon from '@/components/util/VetroIcon.vue';
import ZoomIcon from '@/assets/icons/zoom.svg?inline';
import { NO_PARENT_ICON } from '@/constants';

const MAX_COMMENT_LENGTH = 600;

export default Vue.extend({
  name: 'comment',
  components: {
    VetroIcon,
    ZoomIcon,
  },
  props: {
    comment: {
      type: Object as PropType<Feature>,
      required: true,
    },
    parent: {
      type: Object as PropType<Feature>,
      default: null,
    },
    hovered: { type: Boolean, required: true },
    allowZoomToParent: { type: Boolean, required: true },
  },
  data() {
    return {
      NO_PARENT_ICON,
      expanded: false,
    };
  },
  computed: {
    ...mapGetters('layers', ['layerById', 'layerIdGeomTypeMap', 'layerIdLabelMap']),
    rawCommentText(): string {
      return (this.comment.properties.comment as string) || '';
    },
    shouldTruncateComment(): boolean {
      return this.rawCommentText.length > MAX_COMMENT_LENGTH;
    },
    commentText(): string {
      return this.shouldTruncateComment && !this.expanded
        ? `${this.rawCommentText.slice(0, MAX_COMMENT_LENGTH)}...`
        : this.rawCommentText;
    },
    commentDate(): string {
      return dayjs(this.comment.xVetro.createdTime).format('YYYY/MM/DD hh:mm:ss A');
    },
    commenterName(): string {
      const { firstName, lastName } = this.comment.properties as Record<string, string | undefined>;
      if (firstName && lastName) return `${firstName} ${lastName.charAt(0)}.`;

      if (firstName) return firstName;

      return lastName ?? 'Unknown';
    },
    parentSymbol(): Record<string, LayerSymbol> {
      return getFeatureDefaultSymbolMap(this.parent, this.layerById(this.parent.xVetro.layerId));
    },
    parentLayerLabel(): string {
      return this.parent ? this.layerIdLabelMap[this.parent.xVetro.layerId] : 'Location';
    },
    parentGeomType(): string {
      return this.layerIdGeomTypeMap[this.parent.xVetro.layerId];
    },
  },
});
