










import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    links: {
      type: Object as PropType<Record<string, string>>,
      required: false,
    },
  },
});
