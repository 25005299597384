import axios from 'axios';

const instance = axios.create({
  withCredentials: true,
});

export const submitFile = async ([attributeLabel, value]: [string, unknown]): Promise<Record<
  string,
  unknown
> | null> => {
  if (!(value instanceof File)) return null;

  const {
    data: { result: infoForSave },
  } = await instance.post('/v2/features/file_save/presigned_urls', {
    filenames: [value.name],
  });

  const { key, s3_url: s3Url } = infoForSave[0];
  await instance.put(s3Url, value, { withCredentials: false });
  return { [attributeLabel]: [{ key, label: value.name }] };
};
