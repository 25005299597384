























import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { getFeatureDefaultSymbolMap } from '@/util/features';
import { Feature, Layer } from '@/types';
import VetroIcon from './VetroIcon.vue';
import CommentNumberIcon from './CommentNumberIcon.vue';

export default Vue.extend({
  name: 'feature-label',
  props: {
    feature: { type: Object as PropType<Feature>, required: true },
    showCommentCount: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
  },
  components: {
    VetroIcon,
    CommentNumberIcon,
  },
  computed: {
    ...mapGetters('layers', ['layerById']),
    ...mapGetters('comments', ['commentsByParentId']),
    layer(): Layer {
      return this.layerById(this.feature.xVetro.layerId);
    },
    featureLabel(): string | number | undefined {
      const { labels } = this.layer.style;

      return (
        this.feature.properties.Name ||
        this.feature.properties.ID ||
        this.feature.properties[labels[0]]
      );
    },
    commentCount(): number {
      if (!this.feature.xVetro.vetroId) return 0;
      return this.commentsByParentId[this.feature.xVetro.vetroId]?.length ?? 0;
    },
    iconSizeConfig(): Record<string, number> | undefined {
      return this.small
        ? {
            width: 13,
            height: 13,
            stackGapV: 2.5,
            stackGapH: 2.5,
            maxIconSize: 10,
          }
        : undefined;
    },
  },
  methods: {
    getFeatureDefaultSymbolMap,
  },
});
