




/* eslint-disable @typescript-eslint/no-explicit-any */
// ^ This is here because the second arg for all the `.attr('stroke', (d: any) => d.strokeColor)`
// calls below in the file are quite difficult to type

import Vue, { PropType } from 'vue';
import { select, Selection } from 'd3-selection';
import { transition as d3Transition } from 'd3-transition';
import _isPlainObject from 'lodash.isplainobject';
import { isLineString, isPolygon } from '@nbtsolutions/vetro-mapbox';
import { LayerSymbol } from '@/types';

select.prototype.transition = d3Transition;

// The values here reference our custom font - see vetro-icomoon.svg
// TODO: Store this in db to be shared between fibermap and shared views?
export const ICON_CONTENT_MAP: Record<string, string> = {
  vetro: '\ue900',
  loop: '\ue911',
  office: '\ue912',
  'tower-b': '\ue919',
  tower: '\ue910',
  'line-dotted': '\ue913',
  line: '\ue914',
  house: '\ue915',
  'house-o': '\ue916',
  star: '\ue901',
  'star-o': '\ue902',
  octagon: '\ue91a',
  'octagon-o': '\ue917',
  hexagon: '\ue903',
  'hexagon-o': '\ue904',
  'hexagon-b': '\ue905',
  'hexagon-o-b': '\ue906',
  pentagon: '\ue907',
  'pentagon-o': '\ue908',
  diamond: '\ue909',
  'diamond-o': '\ue90a',
  square: '\ue90b',
  'square-o': '\ue90c',
  triangle: '\ue90d',
  'triangle-o': '\ue90e',
  circle: '\ue90f',
  'circle-o': '\ue918',
  'triangle-b': '\ue91e',
  'triangle-c': '\ue91f',
  'triangle-o-b': '\ue920',
  'triangle-o-c': '\ue921',
  'trapezoid-b': '\ue92a',
  'trapezoid-c': '\ue929',
  coil: '\ue91d',
  bowtie: '\ue925',
  infinity: '\ue927',
  'infinity-b': '\ue928',
  note: '\ue92b',
};

const WIDTH = 25;
const HEIGHT = 25;
const STACK_GAP_V = 5;
const STACK_GAP_H = 5;
const MAX_ICON_SIZE = 20;

type SVGSelection = Selection<SVGSVGElement, unknown, null, undefined>;

interface VetroIconData {
  svg: SVGSelection;
}

// This component renders an SVG icon from symbol information on a layer's style
export default Vue.extend({
  name: 'vetro-icon',
  props: {
    symbols: {
      type: Object as PropType<Record<string, LayerSymbol>>,
      required: true,
    },
    geomType: {
      type: String,
      default: null,
    },
    sizeConfig: {
      type: Object,
      validator: (prop) => {
        const requiredKeys = ['width', 'height', 'stackGapV', 'stackGapH', 'maxIconSize'];
        const propKeys = Object.keys(prop);

        return requiredKeys.filter((key) => propKeys.includes(key)).length === requiredKeys.length;
      },
      default: () => ({
        width: WIDTH,
        height: HEIGHT,
        stackGapV: STACK_GAP_V,
        stackGapH: STACK_GAP_H,
        maxIconSize: MAX_ICON_SIZE,
      }),
    },
  },
  data(): VetroIconData {
    return {
      svg: {} as SVGSelection,
    };
  },
  computed: {
    displaySymbols(): LayerSymbol[] {
      const allSymbols = Object.values(this.symbols);

      if (!_isPlainObject(allSymbols[0])) return [this.symbols as unknown as LayerSymbol];
      if (allSymbols.length === 1) return allSymbols;

      const { Other, ...categorizedStyles } = this.symbols;
      const categorizedSymbols = Object.values(categorizedStyles);

      const displaySymbols = categorizedSymbols.map((s) => {
        if (s.strokeWeight) {
          return {
            ...s,
            strokeWeight: Math.min(s.strokeWeight, 2),
          };
        }

        return s;
      });
      // logic below also used in SET_CATEGORIZED_ATTRIBUTE mutation in the layer store
      if (displaySymbols.length <= 3) return displaySymbols;

      const values = Object.values(displaySymbols).sort((symbolA, symbolB) => {
        if (symbolA.strokeColor < symbolB.strokeColor) {
          return -1;
        }
        if (symbolA.strokeColor > symbolB.strokeColor) {
          return 1;
        }
        return 0;
      });
      const first = values[0];
      const middle = values[Math.floor(values.length / 2)];
      const last = values.slice(-1)[0];

      return [first, middle, last];
    },
  },
  watch: {
    displaySymbols: {
      deep: true,
      handler() {
        this.draw(this.displaySymbols);
      },
    },
  },
  mounted() {
    this.svg = select(this.$refs.vetroIcon as HTMLElement)
      .append('svg')
      .attr('width', this.sizeConfig.width)
      .attr('height', this.sizeConfig.height);

    this.draw(this.displaySymbols);
  },
  methods: {
    draw(data: Array<LayerSymbol>) {
      // remove old data
      this.svg.selectAll('g').remove();

      const maxHeight = this.sizeConfig.height - this.sizeConfig.stackGapV * (data.length - 1);
      const maxWidth = this.sizeConfig.width - this.sizeConfig.stackGapH * (data.length - 1);

      // add new data
      const g = this.svg.selectAll('g').data(data);

      // ENTER new elements present in new data.
      let child: string;

      if (isLineString(this.geomType)) {
        child = 'line';
      } else if (isPolygon(this.geomType)) {
        child = 'rect';
      } else {
        child = 'text';
      }
      g.enter()
        .append('g')
        .each(function appendChild() {
          select(this).append(child);
        });

      this.svg.selectAll('g');

      // UPDATE all the things!
      this.svg
        .selectAll('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', maxWidth)
        .attr('height', maxHeight)
        .attr('fill', (d: any) => d.fillColor)
        .attr('fill-opacity', (d: any) => d.fillOpacity)
        .attr('stroke', (d: any) => d.strokeColor)
        .attr('stroke-width', (d: any) => d.strokeWeight)
        .attr('stroke-opacity', (d: any) => d.strokeOpacity)
        .attr('stroke-dasharray', (d: any) => (d.strokeDasharray || []).toString())
        .attr('stroke-linecap', 'round');

      this.svg
        .selectAll('line')
        .attr('x1', (d: any) => d.strokeWeight / 2)
        .attr('x2', (d: any) => maxWidth - d.strokeWeight / 2)
        .attr('y1', maxHeight / 2)
        .attr('y2', maxHeight / 2)
        .attr('stroke', (d: any) => d.strokeColor)
        .attr('stroke-width', (d: any) => Math.max(d.strokeWeight, 3))
        .attr('stroke-opacity', (d: any) => d.strokeOpacity)
        .attr('stroke-dasharray', (d: any) => d.strokeDasharray)
        .attr('stroke-linecap', 'round');

      this.svg
        .selectAll('text')
        .attr('x', maxWidth / 2)
        .attr('y', maxHeight / 2)
        .attr('fill', (d: any) => d.fillColor)
        .attr('fill-opacity', (d: any) => d.fillOpacity)
        .attr('font-family', 'Vetro-New')
        .attr('font-size', (d: any) => Math.min(d.iconSize, this.sizeConfig.maxIconSize))
        .attr('text-anchor', 'middle')
        .attr('dy', '.35em')
        .each(function addIcon(d: any) {
          (select(this).node() as HTMLElement).innerHTML = ICON_CONTENT_MAP[d.iconClass];
        });

      this.svg
        .selectAll('g')
        .transition()
        .delay(300)
        .attr('transform', (d: any, i: number) => {
          const x = i * this.sizeConfig.stackGapH;
          const y = i * this.sizeConfig.stackGapV;

          return `translate(${x},${y})`;
        });
    },
  },
});
