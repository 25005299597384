







import Vue, { PropType } from 'vue';
import { BlockConfig, Feature } from '@/types';

export default Vue.extend({
  name: 'rich-text-block',
  props: {
    config: { type: Object as PropType<BlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    parentlessCommentsEnabled: { type: Boolean, required: true },
  },
});
