<template>
  <!-- Google Tag Manager (noscript) -->
  <noscript>
    <iframe
      :src="`https://www.googletagmanager.com/ns.html?id=${this.publicId}`"
      height="0"
      width="0"
      style="display: none; visibility: hidden"
    />
  </noscript>
  <!-- End Google Tag Manager (noscript) -->
</template>

<script lang="js">
import Vue from 'vue';

/**
 * Adapted from Google Tag Manager installation snippet. The original was an immediately invoked function call with
 * single letter variable names. This is a non-immediate function with spelled out variable names, that can be
 * called when the component is initialized, but otherwise it does the same thing.
 *
 * There are libraries out there for GTM integrations with Vue, but they don't accommodate multi-tenant applications
 * that would want to initialize GTM somewhere other than at the initialization of the app. If we were to do it that way
 * we would have to know whose shared view is going to be displayed before we even load the config.
 *
 * The downside of this is that we may miss events that would happen before this component is initialized, but in our
 * use case that should be a negligible portion of events, like users clicking on the screen before the app renders.
 */
export default Vue.extend({
  name: 'google-tag-manager',
  props: {
    publicId: { type: String, required: false },
  },
  async mounted() {
    this.initializeGTM(window, document, 'script', 'dataLayer');
  },
  methods: {
    initializeGTM(window, document, tagType, dataLayerName) {
      window[dataLayerName] = window[dataLayerName] || [];
      window[dataLayerName].push({ 'gtm-start': new Date().getTime(), event: 'gtm.js' });
      const firstExistingElement = document.getElementsByTagName(tagType)[0];
      const newElement = document.createElement(tagType);
      const dataLayerQueryParam = dataLayerName !== 'dataLayer' ? `&l=${dataLayerName}` : '';
      newElement.async = true;
      newElement.src = `https://www.googletagmanager.com/gtm.js?id=${this.publicId}${dataLayerQueryParam}`;
      firstExistingElement.parentNode.insertBefore(newElement, firstExistingElement);
    },
  },
});
</script>
