<template>
  <div>
    <b-button-toolbar :class="{ 'sidebar-active': sidebarIsActive, 'selection-toolbar': true }">
      <b-button-group class="top-right-map-button-group">
        <b-button
          class="border px-2 d-flex align-items-center"
          :variant="'danger'"
          @click.stop.prevent="$emit('clear-selected-features')"
        >
          <TrashCanIcon class="funding-area-mode-button-icon mr-1" />
          Clear
        </b-button>
      </b-button-group>
      <b-button-group class="top-right-map-button-group">
        <b-button
          id="click-selection-tool"
          size="sm"
          :variant="selected === SELECTION_MODES.CLICK ? 'primary' : 'light'"
          class="border funding-area-tool-button"
          @click.stop.prevent="setSelectionMode(SELECTION_MODES.CLICK)"
        >
          <ClickingHandIcon style="height: 19px; width: 19px" />
        </b-button>
        <b-tooltip target="click-selection-tool" triggers="hover">
          Multi Select Tool
          <br />
          Hold the control or cmd key and click to multi-select areas
        </b-tooltip>
        <b-button
          id="lasso-selection-tool"
          size="sm"
          :variant="selected === SELECTION_MODES.LASSO_SELECT ? 'primary' : 'light'"
          class="border funding-area-tool-button"
          @click.stop.prevent="setSelectionMode(SELECTION_MODES.LASSO_SELECT)"
        >
          <LassoIcon />
          +
        </b-button>
        <b-tooltip target="lasso-selection-tool" triggers="hover">Lasso Selection Tool</b-tooltip>
        <b-button
          id="lasso-deselection-tool"
          size="sm"
          :variant="selected === SELECTION_MODES.LASSO_DESELECT ? 'primary' : 'light'"
          class="border funding-area-tool-button"
          @click.stop.prevent="setSelectionMode(SELECTION_MODES.LASSO_DESELECT)"
        >
          <LassoIcon />
          -
        </b-button>
        <b-tooltip target="lasso-deselection-tool" triggers="hover">
          Lasso Deselection Tool
        </b-tooltip>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>
<script>
import Vue from 'vue';
import ClickingHandIcon from '@/assets/icons/clicking-hand.svg?inline';
import LassoIcon from '@/assets/icons/lasso.svg?inline';
import TrashCanIcon from '@/assets/icons/trash-can.svg?inline';
import { SELECTION_MODES } from '@/constants';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'feature-selection-buttons',
  components: {
    ClickingHandIcon,
    TrashCanIcon,
    LassoIcon,
  },
  props: {
    selectionMode: String,
    hasFeatureFlag: Boolean,
  },
  data() {
    return {
      SELECTION_MODES,
      selected: this.selectionMode,
    };
  },
  mounted() {
    this.$emit('set-selection-mode', SELECTION_MODES.CLICK);
  },
  computed: {
    ...mapState('sidebar', ['sidebarIsActive']),
  },
  methods: {
    setSelectionMode(mode) {
      if (this.selected === mode) {
        this.$emit('set-selection-mode', null);
        this.selected = null;
      } else {
        this.selected = mode;
        this.$emit('set-selection-mode', mode);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/mixins.scss';
@import '@/assets/variables.scss';

button {
  margin-top: -4px;
}
.funding-area-mode-button-icon {
  height: 14px;
  width: 14px;
}
.top-right-map-button-group {
  padding-top: 10px;
  padding-left: 30px;
}

.selection-toolbar {
  @include media-breakpoint-only(xs) {
    // TODO: What to do on mobile?
    display: none;
  }
}

.funding-area-tool-button {
  width: 50px !important;
  height: 32px !important;
}

.sidebar-active {
  @include media-breakpoint-up(sm) {
    margin-left: $sidebar-width-sm;
  }

  @include media-breakpoint-up(md) {
    margin-left: $sidebar-width-md;
  }

  @include media-breakpoint-up(lg) {
    margin-left: $sidebar-width-lg;
  }

  @include media-breakpoint-up(xl) {
    margin-left: $sidebar-width-xl;
  }
}
</style>
