




















import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'sidebar-toggle-button',
  props: {
    sidebarIsActive: { type: Boolean, required: true },
  },
  computed: {
    ...mapState('config', ['viewAttributes']),
    sidebarToggleButtonText(): string {
      return this.viewAttributes.sidebarConfig?.toggleButtonText ?? '';
    },
    sidebarToggleIcon(): string {
      return this.sidebarIsActive ? '«' : '»';
    },
  },
});
