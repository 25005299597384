




















































import Vue, { PropType } from 'vue';
import { helpers, required, ValidationRule } from 'vuelidate/lib/validators';
import { CustomerAddressInputBlockConfig, Feature, InputBlockConfig } from '@/types';
import ValidatedAttributeInput from '@/components/side-bar/content/blocks/sub-components/ValidatedAttributeInput.vue';
import { ADDRESS_ATTRIBUTE_MAP, UNITED_STATES_ABBREVIATIONS } from '@/util/address';

export default Vue.extend({
  name: 'customer-address-input-block',
  components: {
    ValidatedAttributeInput,
  },
  props: {
    config: { type: Object as PropType<CustomerAddressInputBlockConfig>, required: true },
    parentFeature: { type: Object as PropType<Feature>, required: false },
    disabled: { type: Boolean, required: false, default: false },
    disabledReason: { type: String, required: false, default: '' },
  },
  computed: {
    addressAttributeMap(): Record<string, Record<string, string>> {
      return this.config.addressAttributes || ADDRESS_ATTRIBUTE_MAP;
    },
    administrativeAreaOptions(): string[] {
      return UNITED_STATES_ABBREVIATIONS;
    },
    isUsPostalCodeFormat(): ValidationRule {
      return helpers.regex('PostalCode', /^[0-9]{5}(?:-[0-9]{4})?$/);
    },
    required(): () => ValidationRule {
      return required;
    },
    address1Config(): InputBlockConfig {
      return this.inputBlockConfigFromAddressConfig('address1', this.config.required);
    },
    address2Config(): InputBlockConfig {
      // Address line 2 should never be required.
      return this.inputBlockConfigFromAddressConfig('address2', false);
    },
    localityConfig(): InputBlockConfig {
      return this.inputBlockConfigFromAddressConfig('locality', this.config.required);
    },
    administrativeAreaConfig(): InputBlockConfig {
      return this.inputBlockConfigFromAddressConfig('administrativeArea', this.config.required);
    },
    postalCodeConfig(): InputBlockConfig {
      return this.inputBlockConfigFromAddressConfig('postalCode', this.config.required);
    },
  },
  methods: {
    inputBlockConfigFromAddressConfig(internalName: string, isRequired: boolean) {
      return {
        type: this.config.type,
        key: `${this.config.key}_${internalName}`,
        label: this.addressAttributeMap[internalName]?.label,
        attribute: this.addressAttributeMap[internalName]?.attribute,
        required: isRequired,
      };
    },
  },
});
