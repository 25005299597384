












import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'sidebar-footer',
  computed: {
    ...mapGetters('config', ['getViewAttributes']),
    footerContent(): string {
      return this.getViewAttributes?.sidebarConfig?.footerContent || '';
    },
  },
});
