




import Vue from 'vue';
import userflow from 'userflow.js';
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';
import { isMobile } from '@/util/mobile';

export default Vue.extend({
  name: 'userflow-manager',
  props: {
    token: { type: String, required: false },
  },
  mounted(): void {
    this.initializeUserflow();
  },
  computed: {
    ...mapState('config', ['viewKey', 'viewAttributes']),
  },
  methods: {
    initializeUserflow(): void {
      userflow.init(this.token);
      userflow.identify(uuidv4(), {
        name: this.viewKey,
        device_type: isMobile(window.innerWidth) ? 'mobile' : 'desktop',
      });
    },
  },
});
