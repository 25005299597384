// Currently hardcoded but will need to be dynamic once we get more localities
export const requiredAddressAttributes: string[] = ['Street Address', 'City', 'State', 'Zip Code'];

/*
  Currently hardcoded but will need to be dynamic once we get more localities

  The property "attribute" represents the name of the attribute in a Vetro
  feature's jsonB "attributes" object. Possible name suggestion:
  dbAttributeName
  but that would not be backwards compatible with existing configs.
 */
export const ADDRESS_ATTRIBUTE_MAP: Record<string, Record<string, string>> = {
  fullAddress: { label: 'Address', attribute: 'Address' },
  address1: { label: 'Address', attribute: 'Street Address' },
  address2: { label: 'Apt, suite, etc.', attribute: 'Unit' },
  locality: { label: 'City', attribute: 'City' },
  administrativeArea: { label: 'State', attribute: 'State' },
  postalCode: { label: 'Zip Code', attribute: 'Zip Code' },
};

// Currently hardcoded but will need to be dynamic once we get more localities
export const UNITED_STATES_ABBREVIATIONS: Array<string> = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
